import classNames from "classnames";
import React, { ReactNode } from "react";
import CardContent from "../../components/CardContent/CardContent";
import { ImageRatios } from "../../components/Image/Image";
import ImageCard from "../../components/ImageCard/ImageCard";
import TagInfo from "../../components/TagInfo/TagInfo";
import Text from "../Text/Text";
import GetText from "../../i18n/GetText";
import { urlForDocument } from "../../urls";
import { SanityUnitOfWork, textIdsForUnit } from "../../model/lessons";
import styles from "./UnitOfWorkCard.module.scss";

interface UnitOfWorkCardProps {
  unit: SanityUnitOfWork;
  readMore?: boolean;
  textMaxLines?: number;
  content?: ReactNode;
  className?: string;
  url?: string;
}

const UnitOfWorkCard = ({
  unit,
  readMore = true,
  textMaxLines = 2,
  className,
  url
}: UnitOfWorkCardProps) => {
  const lessonCount = unit.contents?.length || 0;
  const tagInfo = (
    <>
      {lessonCount && (
        <TagInfo
          value={[
            [
              <span key="lessonCount">
                {lessonCount}{" "}
                {unit.unitType === "designChallenge" ? "activities" : "lessons"}
              </span>
            ]
          ]}
        />
      )}
      <TagInfo value={[unit.ageRange.map(a => <GetText id={a} />)]} />
    </>
  );
  url = url ?? urlForDocument(unit);
  return (
    <ImageCard
      widths={400}
      key={unit._id}
      image={unit.image}
      aspectRatio={ImageRatios.Landscape_4_3}
      padded
      className={classNames(styles.root, className)}
      topContent={
        <Text variant="defaultTiny" className={styles.type}>
          <GetText id={textIdsForUnit(unit).singular} />
        </Text>
      }
      href={url}
    >
      <CardContent
        title={unit.title}
        href={url}
        tags={tagInfo}
        textMaxLines={textMaxLines}
        text={unit.description}
        readMore={readMore}
      />
    </ImageCard>
  );
};

export default UnitOfWorkCard;
