import { navigate } from "gatsby";
import React, { useCallback } from "react";
import { Col, Row } from "../../components/Grid";
import { SanityDocument } from "../../model/common";
import { urlForDocument } from "../../urls";
import { useGatsbyLocation } from "../../utils/useGatsbyLocation";
import { SanityUnitOfWork } from "../../model/lessons";
import {
  unitModalUrl,
  useFocussedUnitOfWork
} from "../../utils/unit-modal-util";
import UnitOfWorkCard from "../UnitOfWorkCard/UnitOfWorkCard";
import UnitOfWorkModal from "../UnitOfWorkModal/UnitOfWorkModal";
import styles from "./Listing.module.scss";

interface ResourcesResultsAreaProps {
  page: SanityDocument;
  results: SanityUnitOfWork[];
}

const UnitResultsArea = ({ page, results }: ResourcesResultsAreaProps) => {
  const focussedUnit = useFocussedUnitOfWork(results);
  const { search } = useGatsbyLocation();
  const handleModalClose = useCallback(() => {
    const searchParams = new URLSearchParams(search);
    searchParams.delete("selected");
    const queryString = searchParams.toString();
    navigate(`${urlForDocument(page)}${queryString ? `?${queryString}` : ""}`);
  }, [page, search]);
  return (
    <>
      {focussedUnit && (
        <UnitOfWorkModal
          isOpen={true}
          onClose={handleModalClose}
          value={focussedUnit}
        />
      )}
      <Row>
        {results.map(d => (
          <Col xs={6} sm={6} md={4} className={styles.cardListingCol}>
            <UnitOfWorkCard
              url={unitModalUrl(page, d, search)}
              key={d._id}
              unit={d}
              readMore={false}
              textMaxLines={3}
              className={styles.cardListingCard}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default UnitResultsArea;
