import { SanityDocument } from "../model/common";
import { urlForDocument } from "../urls";
import { useSearchParam } from "../utils/useSearchParam";
import { SanityUnitOfWork } from "../model/lessons";

export const useFocussedUnitOfWork = (
  results: SanityUnitOfWork[]
): SanityUnitOfWork | undefined => {
  const selected = useSearchParam("selected");
  return results.find(unit => unit.slug.current === selected);
};

export const unitModalUrl = (
  page: SanityDocument,
  unit: SanityUnitOfWork,
  searchParams: string = ""
) =>
  urlForDocument(page) +
  searchParams +
  `${searchParams ? "&" : "?"}selected=${encodeURIComponent(
    unit.slug.current
  )}`;
