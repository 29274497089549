import React from "react";
import AnchorButton from "../../components/AnchorButton/AnchorButton";
import Modal from "../../components/Modal/Modal";
import GetText from "../../i18n/GetText";
import { urlForDocument } from "../../urls";
import { SanityUnitOfWork } from "../../model/lessons";
import UnitOfWorkSummary from "../UnitOfWorkSummary/UnitOfWorkSummary";
import styles from "./UnitOfWorkModal.module.scss";

interface UnitOfWorkModalProps {
  value: SanityUnitOfWork;
  isOpen: boolean;
  onClose: () => void;
}

const UnitOfWorkModal = ({ value, isOpen, onClose }: UnitOfWorkModalProps) => {
  return (
    <Modal
      id="event"
      isOpen={isOpen}
      title={value.title}
      onClose={onClose}
      className={styles.root}
      subtitle={<GetText id={`unitType-${value.unitType}`} />}
    >
      <UnitOfWorkSummary
        unit={value}
        text="short"
        className={styles.body}
        showImage
        callToAction={
          <AnchorButton primary to={urlForDocument(value)}>
            <GetText id={`unitType-${value.unitType}`} />
            &nbsp;details
          </AnchorButton>
        }
      />
    </Modal>
  );
};

export default UnitOfWorkModal;
