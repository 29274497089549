import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import { unitOfWorkFilters } from "../components/Listing/config";
import Listing from "../components/Listing/Listing";
import UnitResultsArea from "../components/Listing/UnitResultsArea";
import PageLayout from "../layouts/PageLayout/PageLayout";
import { CommonContext, SanityCampaign } from "../model/common";
import { SanityLessonsPage } from "../model/lessons";
import { GlobalMenus } from "../model/menu";
import { SiteArea } from "../utils/analytics";

export const pageQuery = graphql`
  query UnitOfWorkListingPage($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    listings: sanityUnitOfWorkListingPage(_id: { eq: $_id }) {
      _id
      _type
      title
      _rawSideBarText(resolveReferences: { maxDepth: 5 })
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
    }
    content: allSanityUnitOfWork(
      sort: { fields: [ageRange, title], order: ASC }
    ) {
      totalCount
      edges {
        node {
          ...SanityUnitOfWorkForListing
        }
      }
    }
    thirdPartyResources: sanityThirdPartyLessonConfig {
      thirdPartyResourceOrder {
        _id
        url
        title
        image {
          ...SanityImage
        }
      }
    }
    campaigns: allSanityCampaign(
      filter: { activeSiteAreas: { in: ["lessons", "teach"] } }
    ) {
      nodes {
        ...Campaign
      }
    }
  }
`;

const UnitOfWorkListingPage = ({
  pageContext,
  data: { menus, content, listings, thirdPartyResources, campaigns },
  location
}: UnitOfWorkListingPageProps) => {
  const { _rawSideBarText, title } = listings;

  return (
    <PageLayout
      siteArea={SiteArea.LESSONS}
      metadata={{
        title,
        alternates: pageContext.alternates,
        page: listings
      }}
      menus={menus}
      strings={pageContext.strings}
      location={location}
    >
      <Listing
        page={listings}
        campaigns={campaigns.nodes}
        config={unitOfWorkFilters}
        title={title}
        sideBarText={_rawSideBarText}
        contents={content.edges.map((e: any) => e.node)}
        renderRows={UnitResultsArea}
        thirdPartyResources={
          thirdPartyResources && thirdPartyResources.thirdPartyResourceOrder
        }
        thirdPartyResourcesTitleId="other-lesson-resources"
      />
    </PageLayout>
  );
};

export default UnitOfWorkListingPage;

interface UnitOfWorkListingPageProps {
  data: {
    menus: GlobalMenus;
    listings: SanityLessonsPage;
    content: any;
    thirdPartyResources: any;
    campaigns: { nodes: SanityCampaign[] };
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
