export function classroomUrl() {
  const url = process.env.GATSBY_CLASSROOM_ACTIVITY_URL;
  if (!url) {
    throw new Error("Classroom URL required");
  }
  return url;
}

export function createAiUrl() {
  const url = process.env.GATSBY_CREATEAI_ACTIVITY_URL;
  if (!url) {
    throw new Error("CreateAI URL required");
  }
  return url;
}
