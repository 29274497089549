import kebabCase from "lodash.kebabcase";
import {
  classroomUrl as classroomBaseUrl,
  createAiUrl as createAiBaseUrl
} from "../config";
import { SanityGlobalGoalsResource } from "../model/dyb";
import {
  SanityDownload,
  SanityLesson,
  SanityUnitOfWork
} from "../model/lessons";
import {
  MicrobitCode,
  ProgrammingLanguage,
  SanityCodeDocument
} from "../model/common";
import { SanityTeachTopic } from "../model/teach";

// This needs to match micro:bit classroom.
export const classroomJsonUrlPrefix = "/classroom/activities";
export const downloadsUrlPrefix = "/downloads";

export const findLessonNumber = (
  unit: SanityUnitOfWork,
  lesson: SanityLesson
) => unit.contents.findIndex(l => l._id === lesson._id) + 1;

// We need to ensure we don't pass other editor types
// to classroom or it will fail.
const isSupportedClassroomEditor = (editor: ProgrammingLanguage) =>
  editor === "python" || editor === "makecode";

export const classroomUrl = (
  context: SanityCodeDocument,
  code: MicrobitCode
): string => {
  const prefix =
    { lesson: "Lesson", make: "Project" }[context._type] || "Example";
  const prefixedTitle = `${prefix}: ${context.title}`;
  const language = context.language
    ? `&l=${encodeURIComponent(context.language)}`
    : "";
  return (
    `${classroomBaseUrl()}?id=${activityCodeSlug(
      context,
      code
    )}&project=${encodeURIComponent(prefixedTitle)}&name=${encodeURIComponent(
      code.name || context.title
    )}&editors=${encodeURIComponent(
      code.editors.filter(isSupportedClassroomEditor).join(",")
    )}` + language
  );
};

// We need to ensure we don't pass other editor types
// to createAI or it will fail.
const isSupportedCreateAiEditor = (editor: ProgrammingLanguage) =>
  editor === "makecode";

export const createAiUrl = (
  context: SanityCodeDocument,
  code: MicrobitCode
): string => {
  const prefix =
    { lesson: "Lesson", make: "Project" }[context._type] || "Example";
  const prefixedTitle = `${prefix}: ${context.title}`;
  const language = context.language
    ? `&l=${encodeURIComponent(context.language)}`
    : "";
  return (
    `${createAiBaseUrl()}?id=${encodeURIComponent(
      activityCodeSlug(context, code)
    )}&project=${encodeURIComponent(prefixedTitle)}&name=${encodeURIComponent(
      code.name || context.title
    )}&editors=${encodeURIComponent(
      code.editors.filter(isSupportedCreateAiEditor).join(",")
    )}` + language
  );
};

export const globalGoalsResourceWordDocUrl = (
  context: SanityGlobalGoalsResource
): string =>
  `${
    context.language ? "/" + context.language.toLowerCase() : ""
  }${downloadsUrlPrefix}/global-goals/${kebabCaseFromUniqueParts([
    context.title
  ])}.docx`;

export const teachTopicWordDocUrl = (context: SanityTeachTopic): string =>
  `${downloadsUrlPrefix}/teach/${kebabCaseFromUniqueParts([
    context.title
  ])}.docx`;

export const unitZipUrl = (context: SanityUnitOfWork): string =>
  `${downloadsUrlPrefix}/${kebabCaseFromUniqueParts([context.title])}.zip`;

export const lessonZipUrl = (
  unit: SanityUnitOfWork,
  lesson: SanityLesson
): string => `${downloadsUrlPrefix}/${lessonZipName(unit, lesson)}`;

/**
 * Code downloads where we generate and host the file (MakeCode, Python).
 *
 * Scratch files are direct from the CMS and so should use
 * downloadUrl on the asset directly.
 */
export const codeDownloadUrl = (
  unit: SanityUnitOfWork | undefined,
  context: SanityCodeDocument,
  code: MicrobitCode,
  editor: ProgrammingLanguage,
  extension: string
): string => {
  const lessonNumberPart =
    unit && findLessonNumber(unit, context as SanityLesson).toString();
  return `${downloadsUrlPrefix}/${generatedFileName(
    unit || context,
    [lessonNumberPart, code.name, editor],
    extension
  )}`;
};

export const unitSummaryDownloadName = (unit: SanityUnitOfWork): string => {
  return generatedFileName(unit, ["summary"], "docx");
};

const lessonFileName = (
  unit: SanityUnitOfWork,
  lesson: SanityLesson,
  parts: string[],
  extension: string
): string => {
  const lessonNumber = findLessonNumber(unit, lesson);
  return generatedFileName(
    unit,
    [lessonNumber.toString(), ...parts],
    extension!
  );
};

export const lessonDownloadName = (
  unit: SanityUnitOfWork,
  lesson: SanityLesson,
  download: SanityDownload
): string => {
  const { category, name } = download;
  const { extension } = download.file.asset;
  const parts = [];
  if (category !== "other") {
    parts.push(category);
  }
  parts.push(name);
  return lessonFileName(unit, lesson, parts, extension!);
};

const lessonZipName = (
  unit: SanityUnitOfWork,
  lesson: SanityLesson
): string => {
  const { title } = lesson;
  return lessonFileName(unit, lesson, [title], "zip");
};

export const generatedFileName = (
  context: SanityUnitOfWork | SanityCodeDocument,
  nameParts: (string | undefined)[],
  extension: string
): string => {
  const fileName = kebabCaseFromUniqueParts([context.title, ...nameParts]);
  return `${fileName}.${extension}`;
};

const kebabCaseFromUniqueParts = (parts: (string | undefined)[]): string => {
  const skewered = parts.filter(x => !!x).map(kebabCase);
  // We often have redundant naming in the CMS. Ideally we'd remove
  // redundant (supposedly disambiguating) names but this is a simple fix.
  const deduplicated = Array.from(new Set(skewered));
  return deduplicated.filter(x => !!x).join("-");
};

export const activityCodeSlug = (
  context: SanityCodeDocument,
  code: MicrobitCode
): string => kebabCaseFromUniqueParts([context.title, code.name]);
